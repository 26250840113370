@import "_variables.scss";

.sc-form {
  margin-top: 0;
  //margin-bottom: -$input-margin-bottom;
  margin-left: -12px;
  margin-right: -12px;
}
.sc-submit {
  display: inline-flex;
  align-items: flex-end;
  button {
    margin-bottom: $input-margin-bottom;
  }
}

.sc-form--title {
  font-family: $font-family-secondary;
  font-weight: $font-weight-bold;
  font-size: $font-size-base * 0.875;
  line-height: $line-height-base;
  color: $text-color;
  margin-bottom: 0;
  margin-right: 20px;
}

.sc-form--range {
  display: flex;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
    > span {
      font-weight: $font-weight-light;
      font-size: $font-size-base;
      line-height: $line-height-base;
      color: $text-color;
      margin-bottom: $input-margin-bottom;
      margin-right: 1rem;
    }
  }
}
