@import "_variables.scss";
@import "_mixins.scss";

.c-navbar__info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: $breakpoint-lg) {
    justify-content: flex-start;
    margin-bottom: 3rem;
  }

  ul {
    @include clear_list;
    display: flex;
    align-items: center;
    margin: 0 0 0 0;
    gap: 1.25rem;
    @media screen and (max-width: $breakpoint-lg) {
      flex-direction: column;
      align-items: flex-start;
    }
    li {
      position: relative;
      cursor: pointer;
      &:hover {
        .c-navbar__info-container {
          background-color: $corp-blue-05;
          border-color: $corp-blue-05;
          color: $corp-blue-60;
          > i {
            color: $corp-blue-60;
            @media screen and (max-width: $breakpoint-lg) {
              color: $corp-blue-60;
            }
          }
        }
      }
      @media screen and (max-width: $breakpoint-lg) {
        margin-bottom: 3rem;
        margin-right: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.c-navbar__info-container {
  display: flex;
  align-items: center;
  position: relative;
  padding: 7px 12px;
  background-color: $warm-neutrals-10;
  transition: all 0.3s linear;
  border-radius: 1rem;
  color: $warm-neutrals-60;
  font-family: $font-family-primary;
  font-weight: $font-weight-semibold;
  font-size: $font-size-base * 0.875;
  line-height: $line-height;
  margin-bottom: 0;
  border: 1px solid $warm-neutrals-20;
  text-decoration: none;
  i {
    @media screen and (min-width: $breakpoint-lg) {
      margin-right: 0;
    }
  }
  @media screen and (max-width: $breakpoint-lg) {
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $warm-neutrals-60;
    border: none;
    background-color: $gray-50;
  }
  &:hover,
  &.active,
  &.c-navbar__info-opened {
    background-color: $corp-blue-05;
    border-color: $corp-blue-05;
    color: $corp-blue-60;
    @media screen and (max-width: $breakpoint-lg) {
      background-color: transparent;
      border-color: transparent;
      color: $warm-neutrals-60;
    }
    > i {
      color: $corp-blue-60;
      @media screen and (max-width: $breakpoint-lg) {
        color: $warm-neutrals-60;
      }
    }
  }
}

.c-navbar__info-user {
  padding: 7px 15px;
  i {
    margin-right: 7px;
  }
}

.c-navbar__info-icon {
  color: #626262;
  font-size: $font-size-base;
  margin-right: 0.5rem;
  @media screen and (max-width: $breakpoint-lg) {
    font-size: $font-size-base * 1.5;
    margin-right: 1.5rem;
    color: $gray-600;
  }
}

.c-navbar__info-name {
  position: relative;
}

.c-navbar__info-trailing {
  margin-left: 0.5rem;
  color: $gray-400;
  font-size: $font-size-base * 1.25;
  cursor: pointer;
}

.c-navbar__notifications-icon {
  margin-right: 0;
}

.c-navbar__info--notifications-pending {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: $error-20;
  position: absolute;
  top: 8px;
  right: 15px;
}

.c-navbar__info-toggle {
  @include clear_button;
  display: flex;
  align-items: center;
}

.c-navbar__info--dropdown {
  position: absolute;
  padding: 16px 14px;
  display: flex;
  flex-direction: column;
  background-color: $white;
  width: 100%;
  align-items: flex-start;
  margin-top: 0.5rem;
  border-radius: 4px;
  border: 0.5px solid $warm-neutrals-20;
  margin-left: 10px;
  width: max-content;
  right: 0;
  top: 38px;
  max-width: 200px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  @media screen and (max-width: $breakpoint-lg) {
    position: relative;
    background-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 2.5rem;
    border-top: none;
    border-bottom: none;
    border-right: none;
    border-left: 1px solid $warm-neutrals-20;
    border-radius: 0;
    margin-left: 1.5rem;
    width: 100%;
    margin: 0;
    top: 15px;
  }
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 8px;
    li {
      @include bodyMediumReg;
      color: $warm-neutrals-70;
      margin-bottom: 1.25rem;
      width: 100%;
      @media screen and (max-width: $breakpoint-lg) {
        font-size: $font-size-base;
        line-height: $line-height-base;
      }
      &:last-child {
        margin-bottom: 0;
      }
      button {
        @include clear_button;
        @include bodyMediumReg;
        color: $warm-neutrals-70;
        display: flex;
        width: 100%;
      }
      a {
        width: 100%;
        display: flex;
        align-items: center;
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
      }
      i {
        display: flex;
        margin-right: 0.75rem;
        font-size: $font-size-base * 1.2;
      }
    }
  }
  div {
    @media screen and (max-width: $breakpoint-lg) {
      display: none;
    }
    i {
      color: $warm-neutrals-70;
      font-size: 18px;
    }
    select {
      color: $warm-neutrals-70;
    }
    option {
      @include bodyMediumReg;
      color: $warm-neutrals-70;
    }
  }
  hr {
    height: 1px;
    width: 100%;
    border-color: $warm-neutrals-20;
    margin: 0 0 1.5rem 0;
    opacity: 1;
    @media screen and (max-width: $breakpoint-lg) {
      display: none;
    }
  }
}
