@import "_variables.scss";
@import "_mixins.scss";

.sc-forms-message-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  background-color: #fff;
  border-radius: 100px;
  overflow: visible;
  padding: 0 15px;
  border: 1px solid $warm-neutrals-20;

  .sc-forms-message-input {
    width: 100%;

    input {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
  .sc-forms-message-actions {
    display: flex;
    gap: 12px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}
