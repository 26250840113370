@import "_variables.scss";

.dropdown-row {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  gap: 15px;

  .dropdown-row__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .dropdown-row__label {
      margin-right: auto;
      color: $warm-neutrals-40;
      font-family: $font-family-secondary;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .dropdown-row__action {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 3px;
    cursor: pointer;

    & > .label {
      color: $warm-neutrals-40;
      font-family: $font-family-secondary;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      text-decoration-line: underline;
    }
  }

  .content {
    width: 100%;
  }
}

