@import "_variables.scss";

.list_tile {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
  box-sizing: border-box;
  padding: 7px 0;

  &.radio_mode,
  &.checkbox_mode {
    &:has(.list_tile_leading):has(input[type="radio"]:checked) {
      .list_tile_body {
        .list_tile_title {
          .list_tile_title_trailing {
            color: $warm-neutrals-80;
          }
        }
      }
    }
    .list_tile_body {
      .list_tile_title {
        .list_tile_title_trailing {
          color: $warm-neutrals-40;
        }
      }
    }
  }

  &.checkbox_mode {
    .list_tile_leading {
      padding-top: 2px;
    }
  }

  .list_tile_leading {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .list_tile_body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 7px;

    .list_tile_title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .list_tile_title_text {
        color: $warm-neutrals-80;
        font-size: 12px;
        line-height: 18px;
        font-family: $font-family-primary;
        font-weight: 500;
      }
      .list_tile_title_trailing {
        color: $warm-neutrals-80;
        font-size: 14px;
        line-height: 21px;
        font-family: $font-family-primary;
        font-weight: 400;
      }
    }
    .list_tile_subtitle {
      color: $warm-neutrals-40;
      font-size: 12px;
      line-height: 18px;
      font-family: $font-family-primary;
      font-weight: 400;
    }
  }
}

.list_tile__label {
  padding: 0 10px;
  margin-left: -10px;
  margin-right: -10px;
  width: calc(100% + 20px);
  transition: all 0.2s ease-in-out;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background-color: $corp-blue-05;
  }
}
