@import "_variables.scss";

.sc-chips--chips {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  flex-wrap: wrap;
  > div {
    margin: 0.5rem 0.5rem;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
