.sc-delivery--addmore {
  button {
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #9e9e9e;
    margin-bottom: 1.25rem;
  }
}
