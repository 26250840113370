@import "_variables.scss";

.deposit-terms-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  .deposit-terms-item__label {
    color: $warm-neutrals-80;
    text-overflow: ellipsis;
    font-family: $font-family-secondary;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  .deposit-terms-item__right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    text-align: right;

    .deposit-terms-item__date {
      color: $warm-neutrals-50;
      text-align: right;
      font-family: $font-family-secondary;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
    .deposit-terms-item__amount {
      color: $warm-neutrals-80;
      text-align: right;
      font-family: $font-family-secondary;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
}
