@import "_variables.scss";

.underlined-link {
  &.alternative {
    .text {
      color: $warm-neutrals-40;
    }
  }
  .text {
    text-decoration: underline;
    font-size: 13px;
    line-height: 19px;
    font-weight: 600;
    color: $blue2;
    text-decoration: underline;
    font-family: $font-family-secondary;
  }
}

