@import "_variables.scss";
@import "_mixins.scss";

.c-searchbar {
  position: relative;
  cursor: pointer;
  width: 650px;
  max-width: 100%;
}

.c-searchbar--outline__trailing {
  position: absolute;
  right: 0.9rem;
  top: 0.75rem;
  z-index: 1;
  @media screen and (max-width: $breakpoint-sm) {
    display: none;
  }
  i {
    color: $gray-400;
  }
}

.c-searchbar--rounded__trailing {
  position: absolute;
  right: 0.9rem;
  top: 0.625rem;
  z-index: 1;
  @media screen and (max-width: $breakpoint-sm) {
    display: none;
  }
  i {
    color: $text-color-light;
    font-size: 1rem;
  }
}

.c-searchbar__results {
  background-color: $input-background;
  border: $input-border;
  padding-top: 15px;
  padding-bottom: 15px;
  // border-radius: 23px;
  border-radius: 12px;

  position: fixed;
  width: 100%;
  max-height: 16.25rem;
  overflow: auto;
  z-index: 99;
  // border-top: 0;
  // border-top-left-radius: 0;
  // border-top-right-radius: 0;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 5px;
  min-height: 100px;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 10px;
  //   width: calc(100% - 20px);
  //   height: 1px;
  //   background-color: $blue-20;
  // }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 0.81rem;
      font-weight: $font-weight-light;
      font-size: $font-size-base * 0.875;
      line-height: $line-height-base * 1.1;
      color: $input-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      display: flex;
      vertical-align: middle;
      padding: 10px 15px;
      margin: 0;

      span {
        @include ellipsis;
        padding-right: 0.75rem;
        width: 90%;
        text-align: left;
      }
      i {
        color: $green;
        font-size: $font-size-base;
      }
      &:hover {
        cursor: pointer;
        background-color: $gray-100;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.c-searchbar__results--selected {
  position: absolute;
  right: 0;
  top: 0;
}

.c-searchbar__results--placeholder {
  width: 1rem;
  height: 1rem;
}

.no-pointer-events {
  pointer-events: none;
}

.search-bar-block {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;
  padding: 0 22px;
}

.recent-search-list {
  display: flex;
  width: 100%;
  flex-direction: column;
  width: calc(100% + 44px);
  margin-left: -22px;
}

.recent-search-list-item {
  box-sizing: border-box;
  padding: 6px 22px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 0.875rem;
  color: $warm-neutrals-50;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
  }

  &.new {
    color: $warm-neutrals-60;
  }
}

.fav-materials {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.facet-list-item {
  box-sizing: border-box;
  padding: 6px 22px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: space-between;
  font-weight: 300;
  font-size: 12px;
  color: $gray-300;
  transition: all 0.2s ease-in-out;
  font-family: $font-family-secondary;
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
  }

  .facet-list-item__col {
    display: flex;
    flex-direction: column;
    h6 {
      font-family: $font-family-secondary;
      color: $warm-neutrals-80;
      font-weight: 600;
      margin: 0;
      line-height: 18px;
      font-size: 12px;
    }
    span {
    }

    .facet-list-item__category {
      color: $warm-neutrals-40;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
    }
  }
  .facet-list-item__total {
    color: $warm-neutrals-40;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    span {
    }
  }
}
