@import "_variables.scss";

.sc-header--breadcrumbs {
  display: flex;
  align-items: center;
  i {
    font-size: 1rem;
    margin: 0.25rem;
  }
  ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
    li {
      font-weight: $font-weight-light;
      font-size: $font-size-base * 0.875;
      line-height: $line-height-base;
      color: $text-color-light;
      @media screen and (max-width: $breakpoint-sm) {
        margin-bottom: 0.5rem;
      }
      &:last-child {
        font-weight: $font-weight-semibold;
      }
    }
  }
}
.sc-header--arrow {
  display: flex;
}

