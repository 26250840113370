.c-submenu {
  list-style: none;
  margin: 1.5rem 0 0 0.5rem;
  padding: 0 0 0 2.5rem;
  li {
    padding: 0;
    a {
      padding: 0 !important;
    }
  }
}
