@import "_variables.scss";

.sc-business--search-export {
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: flex-end;
  > div {
    margin-right: 1rem;
    > div > div input {
      margin-bottom: 0;
    }
    + button,
    + button + button {
      margin-right: 1rem;
    }
  }
}
.sc-business--steps {
  margin-bottom: 2rem;
}
