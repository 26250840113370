@import "_variables.scss";
@import "_mixins.scss";

.sc-addresses {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.sc-addresses-delivery-button {
  @include clear_button;
  font-weight: 600;
  font-size: 13px;
  line-height: normal;
  text-decoration-line: underline;
  color: $corp-blue-60;
}
.sc-addresses-use-saved {
  margin-bottom: 1rem;
}
