@import "_variables.scss";

.sc-switch {
  position: relative;
  ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    background: $warm-neutrals-05;
    border: 1px solid $warm-neutrals-20;
    border-radius: 6px;
    position: relative;
    box-sizing: border-box;
    padding: 4px;
  }
  li {
    display: flex;
    align-items: center;
    padding: 6px 10px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: normal;
    transition: all 0.2s linear;
    gap: 10px;
    i {
      font-size: 1rem;
      position: relative;
      z-index: 1;
      transition: all 0.2s linear;
    }
    span {
      position: relative;
      z-index: 1;
      transition: all 0.2s linear;
    }
  }
}
.sc-switch--shadow {
  content: "";
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  border: 0;
  height: calc(100% - 12px);
  background: $corp-blue-05;
  width: 75px;
  border-radius: 4px;
  transition: all 0.3s linear;
  pointer-events: none;
}

