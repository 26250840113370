@import "_variables.scss";

.opportunity-offer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  cursor: pointer;

  &.active {
    border-left: 6px solid $blue;
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 15px 15px 0 15px;
    .content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .title-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 7px;
        .status {
          display: inline-block;
        }
        .title {
          font-family: $font-family-secondary;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 21px;
          color: $warm-neutrals-80;
          margin: 0;
        }
        .transaction-link {
          font-family: $font-family-secondary;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
          text-decoration-line: underline;
          color: $warm-neutrals-40;
        }
      }

      .meta {
        font-family: $font-family-secondary;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        color: $warm-neutrals-80;
        margin: 0;
      }
    }

    .actions {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  .body {
    box-sizing: border-box;
    padding: 0 15px 15px 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 15px 15px 15px;
    .owner {
      font-family: $font-family-secondary;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      text-decoration-line: underline;
      color: $warm-neutrals-50;
      margin: 0;
      a {
        color: $warm-neutrals-50;
      }
    }
    .meta {
      font-family: $font-family-secondary;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: $warm-neutrals-50;
      margin: 0;
      .date {
        margin: 0;
      }
    }
  }
}

.expand-toggler {
}
