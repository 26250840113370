@import "_variables.scss";

.sc-filter--selected {
  margin-left: 0.625rem;
}

.sc-filters--container {
  @media screen and (max-width: $breakpoint-lg) {
    flex-direction: column;
    align-items: flex-start !important;
  }
}
