@import "_variables.scss";
@import "_mixins.scss";

.sc-files {
  display: flex;
  flex-direction: column;
}
.sc-files--file {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  width: 100%;
  span {
    font-weight: $font-weight-light;
    @include typography_size;
    display: flex;
    align-items: center;
    color: $text-color;
    margin: 0 1rem;
  }

  .actions {
    display: flex;
    flex-direction: row;
    gap: 15px;

    & > *:not(:last-child) {
      border-right: 1px solid $warm-neutrals-30;
      padding-right: 15px;
    }
  }
  .button_label {
    font-family: $font-family-secondary;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    text-decoration-line: underline;
    margin: 0;
    white-space: nowrap;
    color: $warm-neutrals-40;
  }

  .file_name {
    font-family: $font-family-secondary;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    width: 100%;
    box-sizing: border-box;
    padding-right: 20px;
  }
}

