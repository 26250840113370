@import "_variables.scss";
@import "_mixins.scss";

.sc-ad-stats {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  &.mobile {
    flex-wrap: nowrap;
    .sc-ad-stats__text {
      font-size: 12px;
    }
  }

  .sc-ad-stats__block {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media only screen and (max-width: 991px) {
      width: 100%;
      padding: 15px 0;
    }

    &:last-child,
    &.sc-ad-stats__block--end {
      margin-left: auto;
      justify-content: flex-end;
    }
  }

  .sc-ad-stats__text {
    color: $text-color-light;
    font-family: $font-family-primary;
    @include typography_size;
    font-weight: 300;
  }
}
