@import "_variables.scss";

.text {
  font-family: $font-family-secondary;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  color: $warm-neutrals-50;
}

.underline {
  text-decoration-line: underline;
}

