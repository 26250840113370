@import "_variables.scss";

.sc-file {
  border: 0.5px dashed $warm-neutrals-20;
  border-radius: 9px;
  padding: 7px 5px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  transition: all 0.1s linear;
  background-color: $input-file-bg-color;
  &.sc-file--hover {
    background-color: #fafafa;
    border: 1px dashed $green;
  }
  &.sc-file--read {
    background-color: $gray-500;
    pointer-events: none;
  }
  &:hover {
    cursor: pointer;
  }
  input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    &:focus {
      outline: none;
    }
  }

  .loading {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
}
.sc-file--icon {
  margin-right: 0.75rem;
  display: flex;
}

.sc-file--label {
  font-weight: $font-weight-light;
  font-size: $font-size-base;
  color: #9e9e9e;
}
.sc-files {
  display: flex;
  flex-direction: column;
}
.sc-files--file {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  span {
    font-weight: $font-weight-light;
    font-size: $font-size-base * 0.81;
    line-height: $line-height-base;
    display: flex;
    align-items: center;
    color: $text-color;
    margin: 0 1rem;
  }
}

