@import '_variables.scss';

.c-language-selector {
    display: flex;
    align-items: center;
    select {
        background: 0 0;
        border: 0;
        color: $white;
        option {
            color: initial;
        }
    }
    i {
        margin-right: .5rem;
        color: $white;
    }
}