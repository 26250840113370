@import "_variables.scss";

.search-input {
  position: relative;

  &.isOpen {
    .search-input__inner {
      .input-wrap {
        // border-bottom: 0;
        // border-bottom-left-radius: 0;
        // border-bottom-right-radius: 0;
        -webkit-box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .search-input__inner {
    .input-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: nowrap;
      border: 1px solid $warm-neutrals-v2-30;
      border-radius: 23px;
      gap: 12px;
      box-sizing: border-box;
      padding: 0 5px 0 22px;
      overflow: hidden;
      transition: all 0.2s ease-in-out;
      background-color: #fff;

      &:hover {
        -webkit-box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.2);
      }

      .leading-icon-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      & > input {
        width: 100%;
        border: 0;
        min-height: 45px;
        outline: none;
        background: none;
        font-size: 14px;
        font-weight: 400;

        &::placeholder {
          color: $warm-neutrals-40;
          font-family: 400;
        }

        &:focus {
        }
      }
    }
  }
}

.hover-shadow {
  & > button {
    &:hover {
      box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.5);
      &:before {
        opacity: 0.1 !important;
      }
    }
  }
}
