@import "../../../styles/_variables.scss";

.sc-heading {
  font-family: $font-family-secondary;
  font-style: normal;
  font-weight: 600;
  font-size: 1.9375rem;
  line-height: 2.375rem;
  color: #282a3a;
  margin-bottom: 1.25rem;

  &.sc-heading-1 {
    font-size: 1.9375rem;
    line-height: 2.375rem;

    @media only screen and (max-width: $breakpoint-lg) {
      font-size: 1.7rem;
      line-height: 2.1rem;
    }
  }

  &.sc-heading-2 {
    font-size: 1.5625rem;
    line-height: 1.875rem;
    @media only screen and (max-width: $breakpoint-lg) {
      font-size: 1.3rem;
      line-height: 1.7rem;
    }
  }

  &.sc-heading-3 {
    font-size: 1.35rem;
    line-height: 1.5rem;
    @media only screen and (max-width: $breakpoint-lg) {
      font-size: 1.2rem;
      line-height: 1.45rem;
    }
  }

  &.sc-heading-4 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    @media only screen and (max-width: $breakpoint-lg) {
      font-size: 1.125rem;
      line-height: 1.25rem;
    }
  }

  &.sc-heading-5 {
    font-size: 1.125rem;
    line-height: 1.25rem;
    @media only screen and (max-width: $breakpoint-lg) {
      font-size: 1rem;
      line-height: 1.15rem;
    }
  }

  &.padding-mobile {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
  }
}
