@import "_variables.scss";

.document_breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;

  .bread {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;
    align-items: center;
    gap: 7px;
    overflow: hidden;
    max-width: 100%;
    .crumb {
      font-size: 17px;
      line-height: 25px;
      font-weight: 600;
      color: $warm-neutrals-40;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &:not(:last-child) {
        &::after {
          content: ">";
          margin-left: 7px;
        }
      }
      &:last-child {
        color: $warm-neutrals-80;
      }
    }
  }
}
