@import "_variables.scss";

.sc-avatar {
  position: relative;
  button {
    right: 0;
    bottom: -10px;
    position: absolute;
    background-color: $green;
    color: $white;
    padding: 1rem;
    border: 3px solid $white;
    i {
      font-size: $font-size-base * 1.1;
    }
  }
}
