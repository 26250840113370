@import "_variables.scss";

.chip_outlined {
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid $warm-neutrals-v2-30;
  background-color: $warm-neutrals-v2-10;
  padding: 1px 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: $warm-neutrals-60;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  max-width: 150px;
  text-overflow: ellipsis;
  transition: all 0.2s ease-in-out;

  &.with-hover {
    cursor: pointer;
    &:hover {
      background-color: $warm-neutrals-v2-30;
    }
  }
}

