@import "_variables.scss";

.sc-title {
  font-family: $font-family-secondary;
  font-weight: $font-weight-semibold;
  font-size: 14px;
  line-height: 21px;
  color: $text-color;
  margin-bottom: 9px;
}
