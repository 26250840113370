.sc-address--pick-deliver {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.75rem;
  > div {
    flex: 1;
    justify-content: center;
  }
}

.sc-address--checkbox {
  margin-bottom: 1rem;
  display: flex;
  align-items: baseline;
  &:last-child {
    margin-bottom: 1.5rem;
  }
}
