@import "_variables.scss";

.material-text {
  font-family: $font-family-secondary;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  font-weight: 400;
  color: $text-color;

  .main {
    color: $text-color;
  }
  .sub {
    color: $text-color-light;
  }
}

