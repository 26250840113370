@import "_variables.scss";

.toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  margin-left: auto;

  cursor: pointer;

  .label {
    font-family: $font-family-secondary;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    text-decoration-line: underline;
    color: $warm-neutrals-40;
  }
}

