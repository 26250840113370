@import "_variables.scss";
@import "_mixins.scss";

.sc-search {
  &.minimal {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 100px;
    border: 1px solid $warm-neutrals-20;
    padding-left: 7px;
    padding-right: 7px;

    &.filtering {
      & > button {
        position: relative;
        &::after {
          content: "";
          width: 5px;
          height: 5px;
          display: block;
          background-color: red;
          border-radius: 50%;
          position: absolute;
          top: 3px;
          right: 3px;
        }
      }
    }

    & > div {
      display: none;
    }

    &.open {
      & > div {
        display: block;
      }
    }

    .search_input {
      border: 0;
      border-radius: 0;
      background-color: transparent;
      padding: 8px;
    }
  }

  &.whiteMode {
    input {
      background-color: #fff;
    }
  }

  div {
    div {
      > span [class*="leading"] {
        + input {
          padding-left: 45;
        }
      }
      > span:first-child {
        top: 13px;
        left: 17px;
        i {
          font-size: 1.1rem;
        }
      }
    }
  }
  input {
    @include bodyMediumReg;
    background: white;
    border: 0.5px solid $warm-neutrals-30;
    border-radius: 30px;
    color: $text-color;
    padding: 12px 17px;
    width: 350px;
    transition: width 0.3s linear;
  }
  input::placeholder {
    @include bodyMediumReg;
    font-weight: $font-weight-light;
    color: $warm-neutrals-40;
  }
  input::-webkit-input-placeholder {
    @include bodyMediumReg;
    font-weight: $font-weight-light;
    color: $warm-neutrals-40;
  }
  input:-moz-placeholder {
    @include bodyMediumReg;
    font-weight: $font-weight-light;
    color: $warm-neutrals-40;
  }
  input::-moz-placeholder {
    @include bodyMediumReg;
    font-weight: $font-weight-light;
    color: $warm-neutrals-40;
  }
  input:-ms-input-placeholder {
    @include bodyMediumReg;
    font-weight: $font-weight-light;
    color: $warm-neutrals-40;
  }
}
