// BASE PATH
$base-path: "../";

// COLORS
$warm-neutrals-05: #f5f5f5;
$warm-neutrals-10: #e1e1e1;
$warm-neutrals-20: #cfcfcf;
$warm-neutrals-30: #b1b1b1;
$warm-neutrals-40: #9e9e9e;
$warm-neutrals-50: #7e7e7e;
$warm-neutrals-60: #626262;
$warm-neutrals-70: #55504b;
$warm-neutrals-80: #3b3b3b;
$warm-neutrals-90: #1c1917;

$warm-neutrals-v2-00: #ffffff;
$warm-neutrals-v2-05: #fcfaf8;
$warm-neutrals-v2-10: #f7f5f2;
$warm-neutrals-v2-20: #e8e4de;
$warm-neutrals-v2-30: #d8d4d0;
$warm-neutrals-v2-40: #aba6a2;
$warm-neutrals-v2-50: #85807d;
$warm-neutrals-v2-60: #655e5a;
$warm-neutrals-v2-70: #55504b;
$warm-neutrals-v2-80: #3e3935;
$warm-neutrals-v2-90: #292524;
$warm-neutrals-v2-90: #1c1917;

$blue-05: #e8f2ff;
$blue-70: #5170d9;

$white: #fff !default;
$gray-50: #f3f4f8 !default;
$gray-100: #e1e1e1 !default;
$gray-200: #cfcfcf !default;
$gray-300: #b1b1b1 !default;
$gray-400: #9496a1 !default;
$gray-500: #f5f5f5 !default;
$gray-600: #626262 !default;
$gray-700: #404252 !default;
$gray-800: #282a3a !default;
$gray-900: #101223 !default;
$black: #000 !default;
$black-80: #282a3a !default;
$blue-20: #d4d6e1 !default;
$blue-20-2: #dae1f4 !default;
$blue-30: #78b2ff !default;
$blue-60: #7f85a6 !default;
$blue-100: #3b5fbb !default;
$teal-20: #d9f1f0 !default;
$teal-100: #41bab3 !default;
$teal-120: #279a93 !default;
$purple-20: #e2dcf9 !default;
$purple-100: #957bdf !default;
$purple-120: #6d50e1 !default;
$error: #e15050 !default;
$error-bg: #f9dcdc !default;
$warning: #fff4cc !default;
$warning-text: #d1a400 !default;
$text-color: #3b3b3b;
$text-color-light: $warm-neutrals-50;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
) !default;

$blue: #63a1ff !default;
$blue2: #3784f6 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #e15050 !default;
$orange: #fd7e14 !default;
$yellow: #ffc800 !default;
$green: #60c386 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
$you-turn: #dff3e7;
$warning: #fff4cc;
$warning-text: #d1a400;
$semantic-error-red-30: #cb3a3a !default;

$corp-blue-05: #ebf3ff !default;
$corp-blue-10: #d4e7ff !default;
$corp-blue-20: #9cc8ff !default;
$corp-blue-30: #78b2ff !default;
$corp-blue-40: #63a1ff !default;
$corp-blue-50: #5891fd !default;
$corp-blue-60: #5683ed !default;
$corp-blue-70: #5170d9 !default;
$corp-blue-80: #4d5fc6 !default;
$corp-blue-90: #463fa4 !default;

$corporate-1: #2a336b;
$corporate-1-800: shade-color($corporate-1, 80%) !default;
$corporate-1-600: shade-color($corporate-1, 60%) !default;
$corporate-1-200: shade-color($corporate-1, 20%) !default;
$corporate-2: $green;
$corporate-2-800: shade-color($corporate-2, 80%) !default;
$corporate-2-600: shade-color($corporate-2, 60%) !default;
$corporate-2-200: shade-color($corporate-2, 20%) !default;

$mainMenuBackgroundColor: #191e40 !default;

// TYPOGRAPHY
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$line-height-base: 1.1875rem; // 19px
$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-semibold: 500 !default;
$font-weight-semibolder: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-size-small: 0.8125rem !default;
$line-height-small: 0.9375rem !default;

// BREAKPOINTS
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

// TYPOGRAPHY
$font-family-primary: "Be Vietnam Pro", sans-serif, -apple-system,
  BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-secondary: $font-family-primary;
$font-family-icons: "Material Icons";
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$line-height: 1.5;
$line-height-base: 1.1875rem; // 19px
$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-semibolder: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;
$font-weight-extra-bold: bolder;

$font-size-small: 0.8125rem;
$line-height-small: 0.9375rem;

// SCRAPBACK BLUE
$corp-blue-05: #ebf3ff;
$corp-blue-10: #d4e7ff;
$corp-blue-20: #9cc8ff;
$corp-blue-30: #78b2ff;
$corp-blue-40: #63a1ff;
$corp-blue-50: #5891fd;
$corp-blue-60: #5683ed;
$corp-blue-70: #5170d9;
$corp-blue-80: #4d5fc6;
$corp-blue-90: #463fa4;

// WARM NEUTRAL
$warm-neutrals-00: #ffffff;
$warm-neutrals-05: #fcfaf8;
$warm-neutrals-10: #f7f5f2;
$warm-neutrals-20: #e8e4de;
$warm-neutrals-30: #d8d4d0;
$warm-neutrals-40: #aba6a2;
$warm-neutrals-50: #85807d;
$warm-neutrals-60: #655e5a;
$warm-neutrals-70: #55504b;
$warm-neutrals-80: #3e3935;
$warm-neutrals-90: #292524;
$warm-neutrals-100: #1c1917;

// COLORS
$text-color: $warm-neutrals-80;
$text-color-light: $warm-neutrals-40;
$white: #fff;
$black: #000;
$green-10: #e2fde2;
$green-20: #3ebe52;
$green-30: #2a8f3a;
$blue-05: #f0faff;
$blue-10: #e0f4ff;
$blue-20: #63a1ff;
$blue-30: #2c64b8;
$yellow-10: #fffcdf;
$yellow-20: #f3bf00;
$yellow-30: #c29c0e;
$red-10: #ffebeb;
$red-20: #e15050;
$red-30: #cb3a3a;
$orange-10: #ffe9db;
$orange-20: #ff833e;
$orange-30: #dc6c3a;
$purple-10: #f4eefc;
$purple-20: #ac7ce9;
$purple-30: #6c39ac;
$gray-10: #f0eeea;
$gray-20: #85807d;
$gray-30: #655e5a;

// STATES
$success-10: $green-10;
$success-20: $green-20;
$success-30: $green-30;
$info-10: $blue-10;
$info-20: $blue-20;
$info-30: $blue-30;
$warning-10: $yellow-10;
$warning-20: $yellow-20;
$warning-30: $yellow-30;
$error-10: $red-10;
$error-20: $red-20;
$error-30: $red-30;

// OPACITIES
$opacity-light: rgba(171, 166, 162, 0.3);
$opacity-medium: rgba(171, 166, 162, 0.6);
$opacity-strong: rgba(171, 166, 162, 0.8);

// CANDIDATES TO REMOVE
$gray-50: #f3f4f8;
$gray-100: #e1e1e1;
$gray-200: #cfcfcf;
$gray-300: #b1b1b1;
$gray-400: #9496a1;
$gray-500: #f5f5f5;
$gray-600: #626262;
$gray-700: #404252;
$gray-800: #282a3a;
$blue: $corp-blue-40;
$blue-20-2: #dae1f4;
$blue-60: #7f85a6;
$blue-100: #3b5fbb;
$teal-20: #d9f1f0;
$teal-100: #41bab3;
$teal-120: #279a93;
$purple-20: #e2dcf9;
$purple-100: #957bdf;
$purple-120: #6d50e1;
$warning: #fff4cc;
$green: #60c386;
$green-20: #3ebe52;
$you-turn: #dff3e7;
$blue: $corp-blue-40;
$blue2: #3784f6;

// Z INDEX
$zindex-sticky: 1020;
$zindex-modal: 1055;

// INPUTS
$input-background: $white;
$input-border: 1px solid $blue-20;
$input-radius: 7px;
$input-padding: 12px 15px;
$input-font-weight: $font-weight-light;
$input-font-size: $font-size-base;
$input-line-height: $line-height-base;
$input-color: $text-color;
$input-margin-bottom: 1rem;
$input-padding-mobile: 0.625rem;
$input-font-size-mobile: $font-size-base;
$input-line-height-mobile: $line-height-base * 0.84;
$input-placeholder-color: $gray-400;
$input-placeholder-padding-mobile: 0.9rem 0.8rem 0.9rem 0.8rem;

// BOX SHADOW
$box-shadow: 0px 0.39px 5.88px rgba(0, 0, 0, 0.1);

// CHIP
$chip-backgound: #e6e6e6;
$chip-color: #626262;

//FILE
$input-file-bg-color: #f8f8f8;
