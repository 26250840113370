@import "../../../styles/_variables.scss";

.sc-tab-title {
  display: flex;
  align-items: center;
  justify-content: center;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    margin-right: 12px;
    line-height: 0;
  }

  .label {
    font-family: $font-family-secondary;
    font-size: $font-size-base * 0.875;
    color: $text-color-light;
    line-height: $line-height-base * 0.84;
    font-weight: inherit;

    @media only screen and (max-width: $breakpoint-lg) {
      font-size: 0.875rem;
    }
  }
  .alert {
    margin-left: 14px;
    width: 7px;
    height: 7px;
    background: $error-20;
    border-radius: 50%;
    line-height: 0;
  }

  &.small {
    @media only screen and (max-width: $breakpoint-lg) {
      .sc-tab-title__inner {
        flex-direction: column;
        gap: 10px;
        text-align: center;
      }

      .icon {
        margin: 0;
      }
    }

    .label {
      font-size: 0.875rem;
    }
  }
}
