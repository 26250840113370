@import "_variables.scss";

.c-header {
  background-image: url("/assets/img/backgrounds/main-header-bg.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 510px;
  @media screen and (max-width: $breakpoint-sm) {
    min-height: 250px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(77, 95, 198, 0.55);
  }
  * {
    position: relative;
  }
}

.c-header__container {
  padding: 150px 0 0 0;
  text-align: center;
}

.c-header__heading {
  font-family: $font-family-secondary;
  color: $white;
  font-size: 44;
  line-height: 1.5;
  font-weight: $font-weight-bold;
}
