@import "_variables.scss";

.bulk_actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .elements_selected {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    .text {
      font-size: 12px;
      color: $warm-neutrals-v2-60;
      line-height: 18px;
      font-weight: 400;
    }
    .close {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 50%;
      transition: all 0.2s ease-in-out;
      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.1);
      }
    }
  }
}

