@import "_variables.scss";

.sc-ad-stat-outter {
  display: inline-block;
  padding: 0 20px;

  @media only screen and (max-width: 991px) {
    padding: 0 15px;
  }

  &:not(:last-child) {
    border-right: 1px solid #9496a1;
  }
}
.sc-ad-stat {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .sc-ad-stat__icon {
    margin-right: 15px;
    color: $text-color-light;
    line-height: normal;
    display: flex;
    line-height: 0;
  }
  .sc-ad-stat__value {
    font-size: $font-size-base;
    line-height: normal;
    color: $text-color-light;
    font-weight: 300;
    font-family: $font-family-primary;
  }
}
