@import "_variables.scss";
@import "_mixins.scss";

.sc-header--subtitle {
  @include heading2;
  color: $warm-neutrals-40;
  margin-bottom: 0;
}
.sc-header--middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 30px 0;
}

.sc-header--lower {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: $breakpoint-lg) {
    align-items: flex-end;
  }
  @media screen and (max-width: $breakpoint-md) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.sc-header--lower-left,
.sc-header--lower-right {
  margin: 0;
}
