@import "_variables.scss";
.sc-transaction--detail-header {
  display: flex;
  align-items: center;

  button {
    position: relative;
    display: flex;
    align-items: center;
    color: $text-color-light;
    i {
      margin-right: 0.75rem;
    }
  }
  @media screen and (max-width: $breakpoint-md) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  > button {
    text-align: left;
    @media screen and (max-width: $breakpoint-md) {
      margin-right: 1rem;
    }
    &:last-child {
      > i {
        margin-right: 0;
      }
    }
  }
}
