.section-header {
  .main {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
  }
  .actions {
    margin-left: auto;
  }
}

