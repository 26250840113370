@import "_variables.scss";
.user_card {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 100%;
  gap: 7px;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 4px;
  &:hover {
    background-color: $warm-neutrals-10;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    .avatar {
      width: 40px;
      height: 40px;
      border: 2px solid $blue-30;
      border-radius: 50%;
      overflow: hidden;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: $warm-neutrals-20;
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
    justify-content: center;
    .name {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      color: $warm-neutrals-50;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      .action {
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        text-align: center;
        color: $semantic-error-red-30;
        appearance: none;
        border: 0;
        background: none;
      }
    }
  }
}

