@import "_variables.scss";
@import "_mixins.scss";

.sc-header--breadcrumbs {
  display: flex;
  align-items: center;
  max-width: 100%;
  i {
    font-size: 1rem;
    margin: 0.25rem;
  }
  ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    li {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 220px;
      @include bodyMediumMed;
      color: $warm-neutrals-40;
      @media screen and (max-width: $breakpoint-sm) {
        margin-bottom: 0.5rem;
      }
      &:last-child {
        font-weight: $font-weight-semibold;
      }
    }
  }
}
.sc-header--arrow {
  display: flex;
}
