@import "_variables.scss";

.sc-alert {
  margin: 15px 0;
  background-color: red;
  box-sizing: border-box;
  padding: 0.9375rem;
  color: #ffffff;
  font-size: 1rem;
  font-family: "Roboto", "Silka", Arial, Helvetica, sans-serif;
  width: 100%;
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.sc-alert--center {
    text-align: center;
  }

  .sc-alert__text {
    font-weight: $font-weight-normal;
    font-size: $font-size-base * 0.81;
    line-height: $line-height-base * 0.58;
    * {
      font-size: inherit;
      line-height: inherit;
    }
    a {
      text-decoration: underline;
      &:hover {
        color: inherit;
      }
    }
    ul {
      margin-top: $line-height-base * 0.58;
    }
    li {
      margin-bottom: $line-height-base * 0.58;
    }
  }
  &.sc-alert--sucess {
    background-color: $success-20;
  }
  &.sc-alert--warning {
    background-color: $warning-10;
    .sc-alert__text {
      color: $warning-20;
    }
  }
  &.sc-alert--info {
    background-color: #e2ecfa;
    .sc-alert__text {
      color: $blue;
    }
  }
  &.sc-alert--error {
    background-color: $error-10;
    .sc-alert__text {
      color: $error-20;
    }

    &.sc-alert--light {
      font-weight: 400;
    }
  }
}
