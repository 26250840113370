@import "_variables.scss";

.sc-select {
  display: block;
  background-color: #fff;
  border: 0.5px solid $warm-neutrals-20;
  border-radius: 5px;
  padding: 10px 15px;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.55859375rem;
  width: 100%;
  color: #282a3a;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, #aba6a2 50%),
    linear-gradient(135deg, #aba6a2 50%, transparent 50%),
    linear-gradient(to right, #fff, #fff);
  background-position: calc(100% - 20px) calc(50% + 2px),
    calc(100% - 15px) calc(50% + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  text-overflow: ellipsis;
  padding-right: 45px;

  &[data-read="true"] {
    background-color: #f3f4f8;
    pointer-events: none;
  }

  &.underline {
    border-radius: 0;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
  }
}
