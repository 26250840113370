@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin clear_button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

@mixin clear_list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin typography_size {
  font-size: $font-size-base * 0.875;
  line-height: $line-height-base * 1.1;
}

@mixin heading1 {
  font-family: $font-family-primary;
  font-size: 30px;
  line-height: 1.5;
  font-weight: $font-weight-semibold;
}

@mixin heading2 {
  font-family: $font-family-primary;
  font-size: 24px;
  line-height: 1.5;
  font-weight: $font-weight-semibold;
}

@mixin heading3 {
  font-family: $font-family-primary;
  font-size: 20px;
  line-height: 1.5;
  font-weight: $font-weight-semibold;
}

@mixin heading4 {
  font-family: $font-family-primary;
  font-size: 17px;
  line-height: 1.5;
  font-weight: $font-weight-semibold;
}

@mixin heading5 {
  font-family: $font-family-primary;
  font-size: 14px;
  line-height: 1.5;
  font-weight: $font-weight-semibold;
}

@mixin bodyLarge {
  font-family: $font-family-primary;
  font-size: 14px;
  line-height: 1.5;
  font-weight: $font-weight-normal;
}

@mixin bodyMediumReg {
  font-family: $font-family-primary;
  font-size: 13px;
  line-height: 1.5;
  font-weight: $font-weight-normal;
}

@mixin bodyMediumMed {
  font-family: $font-family-primary;
  font-size: 13px;
  line-height: 1.5;
  font-weight: $font-weight-semibold;
}

@mixin bodySmallReg {
  font-family: $font-family-primary;
  font-size: 12px;
  line-height: 1.5;
  font-weight: $font-weight-normal;
}

@mixin bodySmallMed {
  font-family: $font-family-primary;
  font-size: 12px;
  line-height: 1.5;
  font-weight: $font-weight-semibold;
}

@mixin bodySmall {
  font-family: $font-family-primary;
  font-size: 10px;
  line-height: 1.5;
  font-weight: $font-weight-normal;
}

@mixin button {
  font-family: $font-family-primary;
  font-size: 13px;
  line-height: 1.5;
  font-weight: $font-weight-semibolder;
}

@mixin buttonLink {
  @include button;
  text-decoration: underline;
}

@mixin linkLarge {
  font-family: $font-family-primary;
  font-size: 14px;
  line-height: 1.5;
  font-weight: $font-weight-normal;
}

@mixin linkMediumReg {
  font-family: $font-family-primary;
  font-size: 13px;
  line-height: 1.5;
  font-weight: $font-weight-normal;
}

@mixin linkMediumMed {
  font-family: $font-family-primary;
  font-size: 13px;
  line-height: 1.5;
  font-weight: $font-weight-semibold;
}

@mixin linkSMall {
  font-family: $font-family-primary;
  font-size: 12px;
  line-height: 1.5;
  font-weight: $font-weight-normal;
}

@mixin inputTag {
  font-family: $font-family-primary;
  font-size: 12px;
  line-height: 1.5;
  font-weight: $font-weight-semibold;
}
