@import "_variables.scss";
@import "_mixins.scss";

.sc-steps {
  overflow-x: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &.minimal {
    width: auto;
    min-width: none;
    ul {
      background-color: transparent;
      border: 0;
      border-radius: 0;
      padding: 10px 0;

      li {
        font-family: $font-family-secondary;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: $warm-neutrals-50;
        text-align: center;
        border-radius: 0;
        padding: 5px 50px;
        width: auto;

        &:first-child {
          padding-left: 15px;
        }
        &:last-child {
          padding-right: 15px;
        }

        &:not(:last-child) {
          border-right: 1px solid $warm-neutrals-20;
        }

        &.active {
          color: $warm-neutrals-80;
          font-weight: 600;
          border-radius: 0;
          background-color: transparent;
        }
      }
    }
  }

  ul {
    list-style: none;
    display: flex;
    align-items: center;
    background-color: transparent;
    border-bottom: 0.5px solid $warm-neutrals-30;
    padding: 0;
    margin: 0;
    width: 100%;
    min-width: max-content;
    min-width: -moz-max-content;
    min-width: -webkit-max-content;
    li {
      padding: 15px 30px;
      @include heading5;
      color: $warm-neutrals-50;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: content-box;
      width: 100%;
      min-width: max-content;
      min-width: -moz-max-content;
      min-width: -webkit-max-content;
      cursor: pointer;
      &::before {
        content: attr(title);
        height: 0;
        visibility: hidden;
        overflow: hidden;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        pointer-events: none;
        font-weight: $font-weight-semibolder;
      }
      &.active,
      &:hover {
        font-weight: $font-weight-semibolder;
        color: $warm-neutrals-80;
        border-bottom: 2.5px solid $corp-blue-40;
      }
    }
  }
}
