@import "_variables.scss";

.sc-menu {
  position: relative;
  z-index: 9;
  .sc-menu__inner {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 1px 10px 0px #0000000f;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    padding: 10px;
    // overflow: hidden;
    border-radius: 4px;
    transform: translate(-25%, 90%);
    border: 1px solid $warm-neutrals-20;
  }
}
