@import "_variables.scss";
@import "_mixins.scss";

.sc-addresses {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.2rem;
  position: relative;
}
.sc-addresses-delivery-button {
  @include clear_button;
  font-weight: $font-weight-normal;
  font-size: $font-size-base * 0.875;
  line-height: $line-height-base * 0.89;
  text-decoration-line: underline;
  color: $gray-400;
  color: $corp-blue-50;
  font-weight: 600;
  font-size: 13px;
}
.sc-addresses-use-saved {
  margin-bottom: 1rem;
  color: $corp-blue-50;
  font-weight: 600;
  font-size: 13px;
}

