@import "_variables.scss";

.sc-log--day {
  font-family: $font-family-secondary;
  margin-bottom: 1.5rem;
  padding: 0.3125rem 1rem;
  background-color: $gray-500;
  font-weight: $font-weight-semibold;
  text-align: center;
  font-size: $font-size-base * 0.8125;
  line-height: $line-height-base * 1.05;
  color: $text-color-light;
  border-radius: 3px;
}

