@import "_variables.scss";

.sc-menu-item {
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  box-sizing: border-box;
  padding: 10px 10px;
  line-height: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  white-space: nowrap;
  border-radius: 4px;
  position: relative;

  &:not(.disabled):hover {
    background-color: rgba(0, 0, 0, 0.04);

    .subitems_wrap {
      display: block;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: no-drop;
  }

  & > i {
    color: $warm-neutrals-50;
    font-size: 16px;
  }

  .more_items_icon_wrap {
    margin-left: auto;
    margin-right: -10px;
  }

  .subitems_wrap {
    position: absolute;
    z-index: 10;
    display: none;
    left: 100%;

    .sc-menu__inner {
      background-color: #fff;
      box-shadow: 0px 1px 10px 0px #0000000f;
      bottom: 0;
      right: 0;
      box-sizing: border-box;
      padding: 10px;
      overflow: hidden;
      border-radius: 4px;
      border: 1px solid $warm-neutrals-20;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
  }
}
