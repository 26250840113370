@import "_variables.scss";

.sc-n-preview {
  width: 500px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: absolute;
  top: calc(100% + 15px);
  gap: 0;
  left: -500%;
  transform: translateX(-50%);
  z-index: 99999;

  &::before {
    content: "";
    width: 80px;
    height: 50px;
    top: -49px;
    display: block;
    position: absolute;
    right: 0;
    margin-left: -40px;
  }

  @media only screen and (max-width: $breakpoint-sm) {
    width: 300px;
  }

  .header {
    box-sizing: border-box;
    padding: 18px 16px;
    border-bottom: 0.5px solid $warm-neutrals-20;
  }

  .body {
    max-height: 350px;
    overflow: auto;
  }

  .footer {
    border-top: 0.5px solid $warm-neutrals-20;
    padding: 10px 16px;
  }
}
