@import "_variables.scss";
@import "_mixins.scss";

.sc-logs--item {
  margin-bottom: 1.25rem;
  @media screen and (max-width: $breakpoint-lg) {
    margin-bottom: 2rem;
  }
}
.sc-logs--item-header {
  display: flex;
  align-items: center;
  > div {
    margin-right: 1rem;
  }
}
.sc-logs--item-separator {
  margin: 0 0.5rem;
}
.sc-logs--item-who {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3b3b3b;
}
.sc-logs--item-date,
.sc-logs--item-action {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  color: #7e7e7e;
}
.sc-logs--item-delete {
  i {
    font-size: 1rem;
  }
}
.sc-logs--item-date {
  margin-bottom: 0;
  margin-right: 0.75rem;
}
.sc-logs--item-body {
  margin-left: 3.375rem;
}
.sc-logs--item-text-info {
  font-weight: 500;
  font-size: $font-size-base * 0.875;
  line-height: $line-height-base * 1.1;
  color: #626262;
  p {
    margin-top: 0.875rem;
    margin-bottom: 0;
  }
}
.sc-logs--item-text {
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  p {
    margin-right: 2.5rem;
    margin-bottom: 0.25rem;
    span {
      margin-right: 1rem;
      + span {
        margin-right: 0;
      }
      button {
        display: inline-block;
      }
    }
  }
  @media screen and (max-width: $breakpoint-lg) {
    align-items: flex-start;
    flex-direction: column;
    button {
      margin-top: 0.75rem;
    }
  }
}
.sc-logs--item-answer {
  p {
    margin-top: 0.875rem;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #7e7e7e;
  }
}
.sc-logs--item-contract {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #3b3b3b;
  i {
    font-size: 1rem;
    color: $text-color-light;
    margin-right: 0.75rem;
  }
  span {
    text-decoration: underline;
  }
}
.sc-logs--item-contracts {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.sc-logs--item-info {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  i {
    font-size: 1rem;
    color: $text-color-light;
    margin-right: 0.75rem;
  }
  p {
    margin-bottom: 0;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #3b3b3b;
  }
}
.sc-logs--item-answers {
  margin-top: 1rem;
  margin-left: 3.375rem;
}
