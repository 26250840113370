@import "_variables.scss";
@import "_mixins.scss";

.sc-radio {
  margin-right: 0.8rem;
  color: $gray-400;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  width: 13px;
  height: 13px;
  border: 1px solid $gray-400;
  border-radius: 50%;
  &:before {
    content: "";
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }
  &:checked:before {
    background: $gray-400;
  }
  + label {
    font-weight: $font-weight-light;
    @include typography_size;
    color: $gray-800;
  }
}
