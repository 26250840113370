@import "_variables.scss";
@import "_mixins.scss";

.sc-chip {
  background-color: $chip-backgound;
  color: $chip-color;
  font-family: $font-family-primary;
  font-weight: $font-weight-semibold;
  font-size: $font-size-base * 0.75;
  line-height: $line-height-base * 0.73;
  text-align: center;
  border-radius: 18px;
  padding: 6px 14px;
  display: flex;
  align-items: center;
  transition: all 0.3s linear;
  white-space: nowrap;
  &.sc-chip--small {
    font-size: $font-size-base * 0.68;
    line-height: $line-height-base * 0.73;
    border-radius: 12px;
  }
  &.sc-chip--big {
    font-size: $font-size-base * 0.875;
    line-height: $line-height-base * 0.94;
    padding: 0.5rem 1rem;
  }
  span {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.12rem;
    transition: opacity 0.3s ease-in;
    border-radius: 50%;
  }
  i {
    font-size: $font-size-base;
  }
}
.sc-chip-seller {
  background-color: $green;
  color: $white;
  &:hover {
    background-color: darken($green, 10%);
  }
  i {
    color: inherit;
  }
}

.sc-chip-buyer {
  background-color: $corp-blue-40;
  color: $white;
  &:hover {
    background-color: darken($corp-blue-40, 10%);
  }
  i {
    color: inherit;
  }
}

.sc-chip-logistics {
  background-color: #6d50e1;
  color: $white;
  &:hover {
    background-color: darken(#6d50e1, 10%);
  }
  i {
    color: inherit;
  }
}

.sc-chip-finances {
  background-color: #3b5fbb;
  color: $white;
  &:hover {
    background-color: darken(#3b5fbb, 10%);
  }
  i {
    color: inherit;
  }
}
.sc-chip-bo {
  background-color: #63a1ff;
  color: $white;
  &:hover {
    background-color: darken(#63a1ff, 10%);
  }
  i {
    color: inherit;
  }
}
.sc-chip-system {
  background-color: #28809b;
  color: $white;
  &:hover {
    background-color: darken(#28809b, 10%);
  }
  i {
    color: inherit;
  }
}

.sc-chip-normal {
  background-color: $chip-backgound;
  color: $chip-color;
  &:hover {
    background-color: darken($chip-backgound, 10%);
  }
  i {
    color: inherit;
  }
}
.sc-chip-pending,
.sc-chip-warning {
  background-color: $warning-10;
  color: $warning-20;
  &:hover {
    background-color: darken($warning-10, 10%);
  }
  i {
    color: inherit;
  }
}
.sc-chip-canceled,
.sc-chip-error {
  background-color: $error-10;
  color: $error-20;
  &:hover {
    background-color: darken($error-10, 10%);
  }
  i {
    color: inherit;
  }
}
.sc-chip-canceled,
.sc-chip-error2 {
  background-color: $error-20;
  color: $white;
  &:hover {
    background-color: darken($error-20, 10%);
  }
  i {
    color: inherit;
  }
}
.sc-chip-signed,
.sc-chip-success {
  background-color: $you-turn;
  color: $green;
  &:hover {
    background-color: darken($you-turn, 10%);
  }
  i {
    color: inherit;
  }
}
.sc-chip-success2 {
  background-color: $green;
  color: $white;
  &:hover {
    background-color: darken($green, 10%);
  }
  i {
    color: inherit;
  }
}
.sc-chip-expired,
.sc-chip-removed {
  background-color: $gray-100;
  color: $text-color-light;
  &:hover {
    background-color: darken($gray-100, 10%);
  }
  i {
    color: inherit;
  }
}
.c-chip_--close {
  margin-left: 0.8125rem;
}

.sc-chip-info {
  background-color: $blue;
  color: $white;
  &:hover {
    background-color: darken($blue, 10%);
  }
  i {
    color: inherit;
  }
}

.sc-chip-info2 {
  background-color: #e2ecfa;
  color: $blue;
  &:hover {
    background-color: darken(#e2ecfa, 10%);
  }
  i {
    color: inherit;
  }
}

.sc-chip-info3 {
  background-color: $teal-100;
  color: $white;
  &:hover {
    background-color: darken($teal-100, 10%);
  }
  i {
    color: inherit;
  }
}

.sc-chip-info4 {
  background-color: $purple-20;
  color: $purple-120;
  &:hover {
    background-color: darken($purple-20, 10%);
  }
  i {
    color: inherit;
  }
}

.sc-chip-info5 {
  background-color: $blue-20-2;
  color: $blue-100;
  &:hover {
    background-color: darken($blue-20-2, 10%);
  }
  i {
    color: inherit;
  }
}

.sc-chip-info6 {
  background-color: $teal-20;
  color: $teal-120;
  &:hover {
    background-color: darken($teal-20, 10%);
  }
  i {
    color: inherit;
  }
}

.sc-chip-temporal {
  background-color: $purple-100;
  color: $white;
  &:hover {
    background-color: darken($purple-100, 10%);
  }
  i {
    color: inherit;
  }
}

.sc-chip-data {
  background-color: $blue-60;
  color: $white;
  &:hover {
    background-color: darken($blue-60, 10%);
  }
  i {
    color: inherit;
  }
}
