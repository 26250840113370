@import "_variables.scss";
@import "_mixins.scss";

.c-text-logo {
  @include heading2;
  text-decoration: none;
  color: $corp-blue-50;
  font-weight: $font-weight-semibolder;
  @media screen and (max-width: $breakpoint-lg) {
    margin-right: auto;
  }
}

.c-text-logo-scrap {
  text-transform: capitalize;
}

.c-text-logo-back {
  text-transform: capitalize;
}
