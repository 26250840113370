@import "_variables.scss";

.sc-form {
}
.sc-submit {
  display: inline-flex;
  align-items: flex-end;
  button {
    margin-bottom: $input-margin-bottom;
  }
}

