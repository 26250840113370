.document_filter {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  &.hideActions {
    max-width: 360px;
  }

  .actions {
  }
}
