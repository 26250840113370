@import "_variables.scss";

.sc-search--export {
  margin-left: auto;
  flex-wrap: wrap;
  @media screen and (max-width: $breakpoint-lg) {
    margin-top: 1rem;
  }
  > div {
    @media screen and (max-width: $breakpoint-sm) {
      margin-bottom: 1rem;
    }
  }
  > div > div > div > input {
    margin-bottom: 0;
    @media screen and (max-width: $breakpoint-lg) {
      width: 100%;
    }
  }
}

.sc-open--export {
  margin-right: 0 !important;
  margin-left: 1rem;
}
