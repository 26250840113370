@import "_variables.scss";

.sc-ads--subheader {
  flex-direction: column;
}

.sc-ads--filters-search {
  box-sizing: border-box;
  padding: 30px 0 20px;
  width: 100%;
}

