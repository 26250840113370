@import "_variables.scss";
@import "_mixins.scss";

.sc-text {
  margin-bottom: 0;
  font-weight: $font-weight-light;
  font-size: $font-size-base * 0.875;
  line-height: $line-height-base * 1.1;
  color: $text-color;
}
.sc-text--bold {
  font-family: $font-family-secondary;
  font-weight: $font-weight-semibold;
  font-size: $font-size-base * 0.875;
  line-height: $line-height-base * 1.1;
  color: $text-color;
}

.sc-text--light {
  font-size: $font-size-base * 0.875;
  line-height: $line-height-base * 1.1;
  color: $text-color-light;
}

.sc-text--ellipsis {
  @include ellipsis;
}
