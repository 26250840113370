@import "_variables.scss";
@import "../Input.module.scss";

.sc-textarea {
  width: 100%;
  //resize: none;
  @extend .c-input;
}

.c-input-clean {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  padding-left: 0;
  padding-bottom: 0;
  &:focus,
  &:active {
    outline: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
}
