@import "_variables.scss";

.kanban_item {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  box-shadow: 0px 2px 10px 0px #2927240d;
  contain: content;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 15px 10px;
  outline: 0;
  overflow: hidden;
  transition: box-shadow 0.2s ease-in;
  min-height: 160px;
  width: 410px;
  box-sizing: border-box;
  // cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.2);
  }

  .header {
    box-sizing: border-box;
    padding: 10px 10px 0;
    display: flex;
    flex-direction: row;
    gap: 15px;

    .title_wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      .title {
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        color: $warm-neutrals-80;
        font-family: $font-family-secondary;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
      }
      .desc {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        color: $text-color-light;
        font-family: $font-family-secondary;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: stretch;
    box-sizing: border-box;
    padding: 5px 10px 10px;
  }

  .tracking {
    box-sizing: border-box;
    padding: 10px;
    border-top: 1px solid $warm-neutrals-10;
  }
  .footer {
    margin-top: auto;
    box-sizing: border-box;
    padding: 10px;
    border-top: 1px solid $warm-neutrals-10;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    .business_name {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      color: $text-color-light;
      font-family: $font-family-secondary;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .date {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      color: $text-color-light;
      font-family: $font-family-secondary;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.address_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;
  overflow: hidden;

  .icon {
  }
  .text {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: $text-color-light;
    font-family: $font-family-secondary;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.item_status {
  color: $blue2;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: $font-family-secondary;
  text-decoration: underline;
  cursor: pointer;
}

.actions {
  position: relative;

  .status_change_action_wrap {
    & > div {
      & > button {
        display: flex;
        flex-direction: column;
        width: 100%;
        cursor: pointer;
      }
    }
  }
}

.toggle_button {
  background-color: $warm-neutrals-10;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  color: $warm-neutrals-50;
  margin-left: auto;
  cursor: pointer;
}
