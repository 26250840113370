body {
  margin: 0;
  font-family: "Be Vietnam Pro", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-tel-input
  .country-list
  .search-box
  .react-tel-input
  .country-list
  li
  span {
  color: #3b3b3b;
  font-weight: 400;
}

.react-tel-input .country-list li span {
  color: #3b3b3b;
  font-weight: 300;
}

.react-tel-input .country-list .search-box {
  color: #3b3b3b !important;
}
