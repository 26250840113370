@import "_variables.scss";

.uploadContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  width: 100%;
}

.dropzone {
  border: 1px dashed $warm-neutrals-20;
  padding: 15px;
  cursor: pointer;
  text-align: center;
  background-color: rgba(252, 250, 248, 0.4);
  border-radius: 8px;
}

.fileListContainer {
  border: 1px solid $warm-neutrals-20;
  padding: 15px 10px;
  background-color: $warm-neutrals-05;
  border-radius: 10px;
}

.fileList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.fileItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 18px 6px 18px;
  gap: 10px;
  &:first-child {
    margin-top: 10px;
  }
  &:hover {
    background-color: $warm-neutrals-10;
  }
}

.deleteButton {
  background: none;
  border: none;
  color: $error-20;
  cursor: pointer;
  margin-left: auto;
}
