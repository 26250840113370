@import "_variables.scss";

.col-avatar {
  margin-bottom: 1rem;
}

.sc-label__hint {
  display: inline-block;
  position: relative;
  overflow: visible;
  cursor: pointer;
  z-index: 99999;
  line-height: 0;
  color: $gray-400;

  &:hover {
    .sc-label__hint__desc {
      opacity: 1;
      right: -190px;
    }
  }

  .sc-label__hint__desc {
    opacity: 0;
    position: absolute;
    right: -99999px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #e2e2e2;
    border-radius: 6px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: opacity 0.4s ease-in-out;
    width: 175px;
    box-sizing: border-box;
    padding: 15px;
    line-height: normal;
    color: $gray-800;
  }
}
