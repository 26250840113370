@import "_variables.scss";

.sc-form {
  margin-top: 0;
  margin-bottom: -$input-margin-bottom;
  margin-left: -12px;
  margin-right: -12px;
}
.sc-submit {
  display: inline-flex;
  align-items: flex-end;
  button {
    margin-bottom: $input-margin-bottom;
  }
}
