@import "_variables.scss";
@import "_mixins.scss";

.sc-files--container {
  &.horizontal {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    flex-wrap: wrap;
  }
}
.sc-files--item {
  padding-bottom: 1.5rem;
}

.sc-files--file {
  display: flex;
  align-items: center;
  @media screen and (max-width: $breakpoint-lg) {
    align-items: center;
    justify-content: center;
  }
  p {
    @include bodyLarge;
    color: $warm-neutrals-50;
    margin-bottom: 0;
    margin-right: 1rem;
  }
}

.actions {
  display: flex;
  align-items: center;
  margin-left: auto;
  > button,
  > span {
    @include button;
    color: $warm-neutrals-40;
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  .name {
    margin-right: 1.25rem;
  }
  .separator {
    margin: 0 1.5rem;
    width: 1px;
    height: 20px;
    background-color: $warm-neutrals-20;
    @media screen and (max-width: $breakpoint-lg) {
      margin: 0 1rem;
      background-color: transparent;
    }
  }
  @media screen and (max-width: $breakpoint-lg) {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
}

.sc-files--info {
  display: flex;
  align-items: center;
  gap: 12px;
}
