@import "_variables.scss";
@import "_mixins.scss";

.c-navbar__menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div > ul {
    @include clear_list;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 22px 0;
    margin-right: 40px;
    gap: 48px;
    @media screen and (max-width: $breakpoint-lg) {
      flex-direction: column;
      align-items: flex-start;
      padding-left: 1.25rem;
      padding-top: 0;
    }
    @media screen and (max-width: $breakpoint-xl) {
      margin-right: 2rem;
    }
    li {
      @include bodyLarge;
      color: $warm-neutrals-70;
      position: relative;
      display: flex;
      align-items: center;
      &::after {
        content: "";
        position: absolute;
        right: -24px;
        top: 6.5px;
        width: 1px;
        height: 22px;
        background-color: $warm-neutrals-10;
        @media screen and (max-width: $breakpoint-lg) {
          content: none;
        }
      }
      &:hover {
        cursor: pointer;
        color: $corp-blue-60;
        font-weight: $font-weight-semibolder;
      }
      &:hover,
      &:has(> a.active, > a:hover) {
        border-radius: 4px;
        background-color: $corp-blue-05;
        button div,
        > i {
          color: $corp-blue-60;
        }
      }
      button {
        i {
          @media screen and (max-width: $breakpoint-lg) {
            margin: 0;
          }
        }
      }
      &.c-navbar__item-submenu {
        display: flex;
        align-items: center;
        ul {
          display: flex;
          flex-direction: column;
          gap: 8px;
          @media screen and (max-width: $breakpoint-lg) {
            padding: 16px 0 0 24px;
            background: white;
            width: 100%;
            margin: 0;
          }

          li {
            padding: 0;
            margin: 0;
            @media screen and (max-width: $breakpoint-lg) {
              padding: 6px 16px;
            }
            &::after {
              content: none;
            }
            a {
              border: none;
            }
          }
        }
        > a {
          border: 0;
          padding-right: 0;
        }
        @media screen and (max-width: $breakpoint-lg) {
          flex-wrap: wrap;
          padding-right: 0;
          &::after {
            content: none;
          }
        }
      }
      i {
        font-size: $font-size-base;
        padding: 6px 8px 6px 16px;
        @media screen and (max-width: $breakpoint-lg) {
          margin-right: 1.56rem;
          font-size: $font-size-base * 1.5;
        }
      }
      @media screen and (max-width: $breakpoint-xl) {
        font-size: $font-size-base * 0.88;
      }
      @media screen and (max-width: $breakpoint-lg) {
        margin-bottom: 0;
        padding-right: 16px;
      }
      > a {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        padding: 6px 16px 6px 16px;
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        display: flex;
        align-items: center;
        color: inherit;
        text-decoration: none;
        transition: border 0.3s linear;
        &.active {
          color: $corp-blue-60;
          font-weight: $font-weight-semibolder;
          @media screen and (max-width: $breakpoint-lg) {
            margin-right: 1.56rem;
            padding: 0;
          }
        }
        &::before {
          content: attr(title);
          height: 0;
          visibility: hidden;
          overflow: hidden;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          pointer-events: none;
          font-weight: $font-weight-semibolder;
        }
        @media screen and (max-width: $breakpoint-xl) {
          padding-left: 20px;
          padding-right: 20px;
        }
        @media screen and (max-width: $breakpoint-lg) {
          border: none;
          padding-bottom: 0;
          padding: 0;
        }
      }
      &:last-child {
        > button {
          padding: 6px 16px 6px 16px;
          border-radius: 4px;

          &:hover {
            background-color: $corp-blue-05;
            color: $corp-blue-60;
          }
          div {
            cursor: pointer;
            display: flex;
            align-items: center;
            i {
              margin: 0;
              padding-right: 0;
              padding-top: 0;
              padding-bottom: 0;
              position: relative;
              right: 8px;
            }
          }
        }
      }
    }
  }
}
.c-navbar__menu-inner {
  display: flex;
  align-items: center;
  @media screen and (min-width: $breakpoint-lg) {
    margin-left: auto;
  }
}
