@import "_variables.scss";

.kanban_column {
  border-radius: 4px;
  contain: content;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 5px 0 0;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.1s linear;
  min-width: 410px;

  .heading {
    background-color: #fff;
    border: 1px solid #fff;
    font-size: 17px;
    line-height: 25px;
    border-radius: 10px;
    color: #626262;
    font-weight: 600;
    font-family: $font-family-secondary;
    box-sizing: border-box;
    padding: 8px 12px;
    text-align: center;
    margin: 0 10px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 10px 0px rgba(41, 39, 36, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .counter {
      background-color: $corp-blue-05;
      color: $corp-blue-70;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      overflow: hidden;
      border-radius: 50%;
    }
  }

  .column_list {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    scrollbar-color: $warm-neutrals-30 transparent;
    scrollbar-width: thin;
  }

  &.clean {
    .heading {
      border: 0;
      background-color: transparent;
      font-size: 20px;
      line-height: 26px;
      color: $text-color;
      font-weight: 600;
      text-align: left;
      margin: 10px 0 -10px 0;
    }
  }
}
