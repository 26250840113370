.c-login-wrap {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  .c-login-wrap__inner {
    box-sizing: border-box;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 520px;
    max-width: 100%;
    margin-top: -260px;
    margin-bottom: 2rem;
    padding: 40px 60px;
    position: relative;
    border-radius: 20px;
    @media only screen and (max-width: 575px) {
      padding: 20px 32px;
      margin-top: 0;
    }
  }

  .c-login-wrap__error {
    margin: 15px 0;
    background-color: red;
    box-sizing: border-box;
    padding: 15px 25px;
    color: #ffffff;
    font-size: 1rem;
    font-family: "Roboto", "Silka", Arial, Helvetica, sans-serif;
    width: 100%;
    font-weight: 500;
  }
}
