@import "_variables.scss";
@import "_mixins.scss";
.sc-transaction--sidebar {
  // background-color: $white;
  // padding: 1rem;
  height: 880px;
  overflow-y: auto;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
  @media screen and (max-width: $breakpoint-md) {
    height: auto;
    margin-top: 2rem;
  }
}
.sc-transaction--line-block {
  width: 100%;
  padding-left: 1rem;
  border-left: 1px solid $blue-20;
  p {
    margin-bottom: 0.875rem;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}
.sc-transactions--link {
  @include clear_button;
  font-family: $font-family-secondary;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  text-decoration-line: underline;
  color: $blue;
}
.sc-transactions--link-small {
  font-weight: $font-weight-normal;
  font-size: $font-size-base * 0.875;
  line-height: $line-height-base * 0.84;
  text-decoration-line: underline;
  color: #9e9e9e !important;
}
