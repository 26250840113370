@import "_variables.scss";
@import "_mixins.scss";

.items {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.item {
  padding: 7px;
  margin-bottom: 5px;
  text-align: left;
  display: flex;
  align-items: center;
  @include bodyMediumReg;
  color: $text-color;
  &:last-child {
    margin: 0;
  }
  button {
    display: flex;
    align-items: center;
  }
}

.subitems {
  list-style: none;
  margin: 0;
  padding: 0 0 0 20px;
}

.check {
  display: inline-block;
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-right: 13px;
}
