@import "_variables.scss";

.container {
  margin-top: 2.18rem;
  flex-wrap: wrap;
}

.sc-filters--container {
  margin-top: 1.25rem;
  padding-bottom: 20px;
}

.sc-separator {
  margin: 0 1rem;
  width: 1px;
  height: 2rem;
  border-left: 0.5px solid #b1b1b1;
  @media screen and (max-width: $breakpoint-md) {
    display: none;
  }
}

