@import "_variables.scss";

.c-input {
  background-color: $input-background;
  border: $input-border;
  border-radius: $input-radius;
  padding: $input-padding;
  font-weight: $input-font-weight;
  font-size: $font-size-base * 0.875;
  line-height: $line-height-base * 1.1;
  width: 100%;
  margin-bottom: $input-margin-bottom;
  color: $input-color;

  @media screen and (max-width: $breakpoint-sm) {
    padding: $input-padding-mobile;
    font-size: $input-font-size-mobile;
    line-height: $input-line-height-mobile;
  }

  &:focus {
    border: $input-border;
  }

  &::placeholder,
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &:-moz-placeholder {
    color: $input-placeholder-color;
    font-weight: $input-font-weight;
    font-size: $input-font-size;
    line-height: $input-line-height;
  }

  @media screen and (max-width: $breakpoint-lg) {
    padding: $input-placeholder-padding-mobile;
  }
}

.c-input--rounded {
  padding: 8px 14px 8px 18px;
  background: $gray-500;
  border-radius: 18px;
  color: $text-color-light;
  font-weight: $font-weight-semibold;
  font-size: $font-size-base * 0.875;
  line-height: $line-height-base * 0.89;
  border: 1px solid $gray-500;
  &::placeholder,
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &:-moz-placeholder {
    color: $text-color-light;
    font-weight: $font-weight-semibold;
    font-size: $font-size-base * 0.875;
    line-height: $line-height-base * 0.89;
  }
}

.c-input,
.c-input--rounded {
  &:read-only {
    background-color: $gray-500;
  }

  &:disabled {
    opacity: 0.6;
  }
  &.c-input-invalid {
    border-color: $error-20;
    border-width: 2px;
  }
}

.c-input-clean {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  padding-left: 0;
}

.c-input-leading {
  padding-left: 2.8rem;
}

.c-input-trailing {
  padding-right: 2.8rem;
}

.c-input-container {
  width: 100%;
}

.c-input-leading--icon {
  position: absolute;
  top: 16px;
  left: 26px;
}

.c-input-validation-message {
  margin-top: -0.6rem;
  font-family: $font-family-secondary;
  font-weight: $font-weight-semibold;
  font-size: $font-size-base * 0.75;
  line-height: $line-height-base * 1.05;
  color: $error-20;
}

.c-input-item {
  display: flex;
  align-items: center;
  position: relative;
}

.c-input-trailing--text {
  margin-bottom: $input-margin-bottom;
  margin-left: 0.75rem;
  color: $text-color;
}

.c-input-trailing--icon {
  position: absolute;
  right: 1rem;
  top: 1rem;
  i {
    font-size: 1rem;
  }
}

.c-input--with-icon {
  padding-right: 3rem;
}

.c-input-item--rounded {
  .c-input-leading--icon {
    top: 4px;
    left: 14px;
  }
}
