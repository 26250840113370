@import "_variables.scss";

.sc-label {
  font-family: $font-family-secondary;
  font-weight: $font-weight-semibold;
  font-size: $font-size-base * 0.75;
  line-height: $line-height-base * 0.94;
  color: $text-color-light;
  margin-bottom: 0.75rem;
}

.sc-label--reduced {
  font-family: $font-family-secondary;
  font-weight: $font-weight-normal;
  font-size: $font-size-base * 0.75;
  line-height: $line-height-base * 0.74;
  color: $gray-400;
  margin-bottom: 0.75rem;
}

.sc-label--light {
  color: #9e9e9e;
}

.sc-label--dark {
  color: $gray-600;
}
