@import './_variables.scss';

@font-face {
    font-family: 'Silka';
    src: url($base-path + '/fonts/silka/Silka-LightItalic.eot');
    src: url($base-path + '/fonts/silka/Silka-LightItalic.eot?#iefix') format('embedded-opentype'),
        url($base-path + '/fonts/silka/Silka-LightItalic.woff2') format('woff2'),
        url($base-path + '/fonts/silka/Silka-LightItalic.woff') format('woff'),
        url($base-path + '/fonts/silka/Silka-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Silka';
    src: url($base-path + '/fonts/silka/Silka-BoldItalic.eot');
    src: url($base-path + '/fonts/silka/Silka-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url($base-path + '/fonts/silka/Silka-BoldItalic.woff2') format('woff2'),
        url($base-path + '/fonts/silka/Silka-BoldItalic.woff') format('woff'),
        url($base-path + '/fonts/silka/Silka-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Silka';
    src: url($base-path + '/fonts/silka/Silka-Regular.eot');
    src: url($base-path + '/fonts/silka/Silka-Regular.eot?#iefix') format('embedded-opentype'),
        url($base-path + '/fonts/silka/Silka-Regular.woff2') format('woff2'),
        url($base-path + '/fonts/silka/Silka-Regular.woff') format('woff'),
        url($base-path + '/fonts/silka/Silka-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Silka';
    src: url($base-path + '/fonts/silka/Silka-RegularItalic.eot');
    src: url($base-path + '/fonts/silka/Silka-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url($base-path + '/fonts/silka/Silka-RegularItalic.woff2') format('woff2'),
        url($base-path + '/fonts/silka/Silka-RegularItalic.woff') format('woff'),
        url($base-path + '/fonts/silka/Silka-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Silka';
    src: url($base-path + '/fonts/silka/Silka-Bold.eot');
    src: url($base-path + '/fonts/silka/Silka-Bold.eot?#iefix') format('embedded-opentype'),
        url($base-path + '/fonts/silka/Silka-Bold.woff2') format('woff2'),
        url($base-path + '/fonts/silka/Silka-Bold.woff') format('woff'),
        url($base-path + '/fonts/silka/Silka-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Silka Extra';
    src: url($base-path + '/fonts/silka/Silka-ExtraLightItalic.eot');
    src: url($base-path + '/fonts/silka/Silka-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url($base-path + '/fonts/silka/Silka-ExtraLightItalic.woff2') format('woff2'),
        url($base-path + '/fonts/silka/Silka-ExtraLightItalic.woff') format('woff'),
        url($base-path + '/fonts/silka/Silka-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Silka';
    src: url($base-path + '/fonts/silka/Silka-Light.eot');
    src: url($base-path + '/fonts/silka/Silka-Light.eot?#iefix') format('embedded-opentype'),
        url($base-path + '/fonts/silka/Silka-Light.woff2') format('woff2'),
        url($base-path + '/fonts/silka/Silka-Light.woff') format('woff'),
        url($base-path + '/fonts/silka/Silka-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Silka Extra';
    src: url($base-path + '/fonts/silka/Silka-ExtraLight.eot');
    src: url($base-path + '/fonts/silka/Silka-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url($base-path + '/fonts/silka/Silka-ExtraLight.woff2') format('woff2'),
        url($base-path + '/fonts/silka/Silka-ExtraLight.woff') format('woff'),
        url($base-path + '/fonts/silka/Silka-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Silka';
    src: url($base-path + '/fonts/silka/Silka-Black.eot');
    src: url($base-path + '/fonts/silka/Silka-Black.eot?#iefix') format('embedded-opentype'),
        url($base-path + '/fonts/silka/Silka-Black.woff2') format('woff2'),
        url($base-path + '/fonts/silka/Silka-Black.woff') format('woff'),
        url($base-path + '/fonts/silka/Silka-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Silka';
    src: url($base-path + '/fonts/silka/Silka-Medium.eot');
    src: url($base-path + '/fonts/silka/Silka-Medium.eot?#iefix') format('embedded-opentype'),
        url($base-path + '/fonts/silka/Silka-Medium.woff2') format('woff2'),
        url($base-path + '/fonts/silka/Silka-Medium.woff') format('woff'),
        url($base-path + '/fonts/silka/Silka-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Silka';
    src: url($base-path + '/fonts/silka/Silka-SemiBoldItalic.eot');
    src: url($base-path + '/fonts/silka/Silka-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url($base-path + '/fonts/silka/Silka-SemiBoldItalic.woff2') format('woff2'),
        url($base-path + '/fonts/silka/Silka-SemiBoldItalic.woff') format('woff'),
        url($base-path + '/fonts/silka/Silka-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Silka';
    src: url($base-path + '/fonts/silka/Silka-BlackItalic.eot');
    src: url($base-path + '/fonts/silka/Silka-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url($base-path + '/fonts/silka/Silka-BlackItalic.woff2') format('woff2'),
        url($base-path + '/fonts/silka/Silka-BlackItalic.woff') format('woff'),
        url($base-path + '/fonts/silka/Silka-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Silka';
    src: url($base-path + '/fonts/silka/Silka-MediumItalic.eot');
    src: url($base-path + '/fonts/silka/Silka-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url($base-path + '/fonts/silka/Silka-MediumItalic.woff2') format('woff2'),
        url($base-path + '/fonts/silka/Silka-MediumItalic.woff') format('woff'),
        url($base-path + '/fonts/silka/Silka-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Silka';
    src: url($base-path + '/fonts/silka/Silka-ThinItalic.eot');
    src: url($base-path + '/fonts/silka/Silka-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url($base-path + '/fonts/silka/Silka-ThinItalic.woff2') format('woff2'),
        url($base-path + '/fonts/silka/Silka-ThinItalic.woff') format('woff'),
        url($base-path + '/fonts/silka/Silka-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Silka';
    src: url($base-path + '/fonts/silka/Silka-SemiBold.eot');
    src: url($base-path + '/fonts/silka/Silka-SemiBold.eot?#iefix') format('embedded-opentype'),
        url($base-path + '/fonts/silka/Silka-SemiBold.woff2') format('woff2'),
        url($base-path + '/fonts/silka/Silka-SemiBold.woff') format('woff'),
        url($base-path + '/fonts/silka/Silka-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Silka';
    src: url($base-path + '/fonts/silka/Silka-Thin.eot');
    src: url($base-path + '/fonts/silka/Silka-Thin.eot?#iefix') format('embedded-opentype'),
        url($base-path + '/fonts/silka/Silka-Thin.woff2') format('woff2'),
        url($base-path + '/fonts/silka/Silka-Thin.woff') format('woff'),
        url($base-path + '/fonts/silka/Silka-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}