.composition_input {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
  justify-content: flex-start;
  .selector_wrap {
  }
  .option_list {
  }
}
