@import "_variables.scss";

.file_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 280px;
  box-sizing: border-box;
  padding: 7px;
  background-color: $warm-neutrals-05;
  border: 1px solid $warm-neutrals-10;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;
  gap: 10px;

  &:hover {
    box-shadow: 0px 1px 10px 0px #0000000f;

    .selected_check {
      opacity: 1;
    }
  }

  &.selected {
    .selected_check {
      opacity: 1;
      background-color: #5891fd;
      &::after {
        content: "check";
        font-family: $font-family-icons;
        color: #fff;
        line-height: 0;
        position: relative;
        top: -1px;
      }
    }
  }

  .selected_check {
    display: block;
    width: 18px;
    height: 18px;
    border: 1px solid $warm-neutrals-20;
    border-radius: 3px;
    position: absolute;
    top: 8px;
    left: 8px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    background: rgba(0, 0, 0, 0.16);
    &::before {
      transition: all 0.2s ease-in-out;
      content: "";
      background-color: rgba($color: #000000, $alpha: 0.08);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      position: absolute;
      top: -7px;
      left: -7px;
      opacity: 0;
    }
    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  &.folder {
    .file_menu {
      position: absolute;
      top: 0px;
      right: 0px;
    }

    .header {
      box-sizing: border-box;
      padding: 30px 10px 10px 10px;
    }

    .body {
      overflow: hidden;
      padding-bottom: 20px;
      max-height: 42px;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      .title_folder {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: $warm-neutrals-80;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0;
        text-align: center;
      }
    }
  }

  &.is-file {
    .selected_check {
      top: 12px;
      left: 10px;
    }
  }

  .header {
    .preview-image {
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: $warm-neutrals-10;
      height: 158px;
      width: 268px;
      border: 1px solid $warm-neutrals-10;
      border-radius: 4px;
      box-sizing: border-box;
    }
  }

  .body {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-left: 5px;
    .title_file {
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      color: $warm-neutrals-80;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0;
      text-align: center;
    }

    .file_menu {
      margin-left: auto;
    }
  }
}
