@import "_variables.scss";

.link-with-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  margin: 0 -5px;
  cursor: pointer;
  width: calc(100% + 10px);

  &:hover {
    background-color: $blue-05;
  }

  .text {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: $warm-neutrals-v2-70;
    margin: 0;
  }
}

