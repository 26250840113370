@import "_variables.scss";
@import "_mixins.scss";

.c-navbar {
  background-color: $white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  //padding: 2rem 0 0;
  position: relative;
  z-index: 100;
  @media screen and (max-width: $breakpoint-lg) {
    padding-bottom: 1rem;
    position: static;
    padding: 2rem 0 2rem;
  }
  @media screen and (max-width: $breakpoint-sm) {
    box-shadow: none;
  }
}

.c-navbar__container {
  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
}

.c-navbar__container-mobile {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  display: none;
  transition: all 0.7s ease-in-out;
  overflow: auto;
  //padding: 2.8125rem 2rem;
  @media screen and (min-width: $breakpoint-lg) {
    display: none;
  }
  &.open {
    opacity: 1;
    display: flex;
    flex-direction: column;
    z-index: $zindex-modal;
    background: $white;
  }
}

.c-navbar__shrink-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media screen and (min-width: $breakpoint-lg) {
    display: none;
  }
}

.c-navbar__shrink-title {
  font-family: $font-family-secondary;
  font-weight: $font-weight-bold;
  font-size: $font-size-base * 1.68;
  line-height: $line-height-base * 1.73;
  color: $text-color;
  margin-bottom: 0;
  margin-left: 0.625rem;
  flex: 1;
}

.c-navbar__shrink-back {
  flex: 1;
}

.c-navbar__toggle {
  display: flex;
  align-items: center;
  z-index: $zindex-sticky;
  position: relative;
  button {
    @include clear_button;
    display: flex;
    margin-left: 2rem;
  }
}

.c-navbar__mobile--header {
  margin-top: 1.25rem;
  padding: 0 0.75rem 0 1.56rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-navbar__mobile--menu {
  padding: 3.125rem 2.81rem 3.125rem 1.56rem;
}

.c-navbar__mobile--info {
  flex-grow: 1;
  background-color: $gray-50;
  padding: 3.125rem 2.81rem 3.125rem 1.56rem;
}

.c-navbar__mobile--notifications {
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #f8f8f8;
  transition: all 0.3s linear;
  border-radius: 1rem;
  border: 1px solid $gray-100;
  color: $gray-400;
  text-decoration: none;
  i {
    font-size: 1rem;
    color: #626262;
  }
}

.c-navbar__mobile--notifications-pending {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: $error-20;
  position: absolute;
  top: 8px;
  right: 15px;
}

.c-navbar__mobile--language {
  > div {
    display: flex !important;
    padding-left: 1.25rem;
    i {
      color: $gray-600;
      margin-right: 1.5rem;
      font-weight: $font-weight-light;
    }
    select {
      color: $gray-600;
      font-weight: $font-weight-light;
    }
    option {
      color: $gray-600;
    }
  }
}

.c-navbar__mobile--logo {
  margin-right: auto;
}
