@import "_variables.scss";

.tab-panel-wrap {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .title {
    font-family: $font-family-secondary;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    margin: 0;
  }
  .action {
    margin-left: auto;
  }
  .body {
    display: block;
    width: 100%;
  }
}

