@import "_variables.scss";
@import "_mixins.scss";

.container {
  margin-top: 20px;
  width: 100%;
  gap: 10px;
  height: 30px;
}
.character,
.character--filled,
.character--selected,
.character--inactive {
  @include bodyLarge;
  color: $text-color;
  background-color: white;
  border-bottom: 0.5px solid $warm-neutrals-20;
  border-top: none;
  border-left: none;
  border-right: none;
  height: 30px;
  outline: 0;
}
