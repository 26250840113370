@import "_variables.scss";

.offer_accepted {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 15px;
  gap: 10px;
  border-radius: 10px;
  overflow: hidden;
  background-color: $blue;
  color: #fff;
  margin-top: 15px;

  .header {
    display: flex;
    flex-direction: column;
    .heading {
      font-family: $font-family-secondary;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      color: #fff;
      margin: 0;
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .text {
      font-family: $font-family-secondary;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      color: #fff;
      margin: 0;
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

