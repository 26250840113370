@import "_variables.scss";

.label {
  color: $warm-neutrals-40;
  font-family: $font-family-secondary;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  text-decoration-line: underline;
}

