@import "_variables.scss";

.sc-cardbox {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sc-cardbox--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  button {
    min-width: 100px;
    justify-content: flex-end;
  }
}

.sc-cardbox--title {
  font-family: $font-family-secondary;
  font-weight: $font-weight-semibolder;
  font-size: $font-size-base * 1.25;
  line-height: $line-height-base * 1.57;
  color: $text-color;
  margin-bottom: 0;
}

.sc-cardbox--action {
  font-size: $font-size-base * 0.875;
  line-height: $line-height-base * 1.1;
  text-decoration-line: underline;
  color: #9e9e9e;
}

.sc-cardbox--content {
}
