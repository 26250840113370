@import "_variables.scss";

.document-location-item {
  box-sizing: border-box;
  padding: 5px 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border-bottom: 1px solid $warm-neutrals-v2-30;

  &.selected,
  &:hover {
    background-color: $blue-05;

    .radio {
      border-color: #63a1ff;
      &::before {
        background-color: #63a1ff;
      }
    }
  }

  .inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .actions {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 5px;
    }
  }

  .radio {
    position: relative;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid $warm-neutrals-v2-50;
    &::before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: transparent;
      top: 2px;
      left: 2px;
      transition: all 0.2s ease-in-out;
    }
  }
}

