.sc-push-toast-wrap {
  width: auto;
  max-width: 100%;
}

.sc-push-toast {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  > div + button {
    color: #7e7e7e;
    opacity: 1;
    position: relative;
    top: 16px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.sc-push-toast__body {
  width: 100%;
}
