@import "_variables.scss";

.sc-transaction--detail {
  // background-color: $gray-500;
  background-color: transparent;
}

.sc-transaction--header {
  background-color: $white;
  margin-bottom: 1.5rem;
}

.sc-transaction--main {
}

.sc-transaction--log {
  width: 100%;
}
.sc-logs--container {
  // background-color: $white;
  background-color: transparent;
  display: flex;
  flex-direction: column;
}

.sc-transaction--search {
  // border-bottom: 1px solid $gray-200;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 7px;
  input {
    margin-bottom: 0;
  }
}

.sc-logs {
  padding: 0 0 0 1rem;
  height: 700px;
  margin-top: 7px;
  background-color: $white;
  border-radius: 10px;
  border: 1px solid $warm-neutrals-10;
  > div > div > div {
    padding-right: 1rem;
  }
}

.sc-forms {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  overflow-y: visible;
  overflow-x: visible;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  background-color: #fff;
  height: 100%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px rgba(97, 97, 97, 0.1);

  &.minimal {
    background-color: transparent;
    box-shadow: none;

    .react-tabs__tab-list {
      border-bottom: 0;
      gap: 12px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 12px;

      .react-tabs__tab {
        border-radius: 30px;
        border: 2px solid $blue;
        padding: 5px 15px;
        width: auto;
        background-color: #fff;

        i {
          color: $blue !important;
        }
        svg * {
          fill: $blue !important;
        }

        div[class*="label"] {
          color: $blue;
          font-size: 13px;
          font-weight: 600;
        }

        &.selected {
          box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.5);
        }

        &:not(:last-child) {
          &::before {
            display: none;
          }
        }
      }
    }

    .react-tabs__tab-panel {
      & > button {
        margin-left: auto !important;
        margin: 0;
      }
    }
  }

  &__tab-list {
    border-bottom: 1px solid #cfcfcf;
    margin: 0 0 0;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 20px 0;
  }

  &__tab {
    border-bottom: none;
    bottom: -1px;
    list-style: none;
    padding: 0 20px;
    border-right: 1px solid #cfcfcf;
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: $font-family-secondary;
    font-size: 1rem;
    color: $gray-700;
    font-weight: 300;
    button {
      margin: -20px -20px;
      padding: 20px 20px;
    }
    :last-child {
      border-right: 0;
    }
    button + div[class*="sc-dropdown--content"] {
      padding: 0;
    }
    ul {
      padding: 10px;
      li {
        margin: 0;
        padding: 0;
        &:hover {
          button {
            background-color: $warm-neutrals-05;
          }
        }
        button {
          padding: 8px 16px;
          margin: 0;
          width: 100%;
          border-radius: 3px;
        }
      }
    }
    &:not(:last-child) {
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        height: 60%;
        width: 1px;
        background-color: $gray-200;

        @media only screen and (max-width: $breakpoint-lg) {
          height: 40%;
        }
      }
    }

    i {
      font-size: 1.125rem;
    }

    svg {
      width: 18px;
    }

    &.selected {
      font-weight: 500;
      > div > div div + div {
        color: $text-color;
      }
      button {
        > div > div > div + div {
          color: $text-color;
        }
        > div + i {
          color: $text-color !important;
        }
      }
      > button + div {
        li {
          font-weight: $font-weight-light;
        }
      }
      i {
        color: $green !important;
        font-size: 1.125rem;
      }

      svg * {
        fill: $green !important;
      }
    }

    &--disabled {
      color: $gray-200;
      cursor: default;
    }

    &:focus {
      outline: none;

      &:after {
        content: "";
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: #fff;
      }
    }
  }

  &__tab-panel {
    display: none;
    height: 100%;
    box-sizing: border-box;
    padding: 1.25rem;

    &--selected {
      display: block;
    }
  }

  &.mobile-fixed {
    @media only screen and (max-width: $breakpoint-lg) {
      // position: fixed;
      // bottom: 0;
      // left: 0;
      width: 100%;
      border: 0;
      border-top: 1px solid #cfcfcf;
      border-radius: 0;

      .react-tabs__tab-list {
        margin-bottom: 0;
      }

      .react-tabs__tab-panel {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        border-top: 1px solid #cfcfcf;
        display: none;
        background-color: #fff;
        &.mobile-open {
          display: block;
        }
      }
    }
  }
}
main {
  padding-bottom: 2rem;
}
