@import "_variables.scss";

.sc-back-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: $warm-neutrals-60;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;

  @media screen and (max-width: $breakpoint-sm) {
    margin-top: 0;
    margin-bottom: 0;
  }

  &:hover {
    .sc-back-button__icon {
      transform: translateX(-5px);
    }
  }

  .sc-back-button__icon {
    margin-right: 10px;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
  }

  .sc-back-button__text {
    color: $warm-neutrals-60;
    font-size: 13px;
    line-height: 1.5;
  }
}
