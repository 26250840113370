@import "_variables.scss";
@import "_mixins.scss";

.sc-files--file {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  width: 100%;
  span {
    font-weight: $font-weight-light;
    @include typography_size;
    display: flex;
    align-items: center;
    color: $text-color;
    margin: 0 1rem;
  }

  .actions {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
  }
}

