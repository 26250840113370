@import "_variables.scss";

.sc-ads--categories {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 1.25rem;
  @media screen and (max-width: $breakpoint-md) {
    flex-wrap: wrap;
  }
  button {
    margin-right: 1rem;
    @media screen and (max-width: $breakpoint-md) {
      margin-bottom: 1rem;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
