@import "_variables.scss";

.opportunity_info_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  .label {
    font-family: $font-family-secondary;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    color: $warm-neutrals-40;

    &::after {
      content: "-";
      margin-left: 10px;
    }
  }
  .desc {
    font-family: $font-family-secondary;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    color: $warm-neutrals-80;
  }
}
