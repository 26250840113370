@import "_variables.scss";

.sc-business--detail {
  > div {
    > div {
      > div {
        padding-top: 6px;
        padding-bottom: 12px;
      }
    }
  }
}

.col-ref {
  > div {
    height: 100%;
  }
}

.grouped {
  padding: 0 12px 12px 12px;
  @media screen and (max-width: $breakpoint-xl) {
    padding: 12px;
  }
}

.sc-business--subheader {
  display: flex;
  align-items: center;
  > div {
    @media screen and (max-width: $breakpoint-lg) {
      margin-bottom: 1rem;
    }
  }
  @media screen and (max-width: $breakpoint-lg) {
    flex-direction: column;
    align-items: flex-start;
  }
}
