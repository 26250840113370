@import "_variables.scss";
.c-icon-button {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
  position: relative;
  transition: background-color 0.2s ease-in-out;
  box-sizing: border-box;
  margin: 5px;
  border: 0;
  color: #555c89;
  padding: 5px;
  &:disabled {
    background-color: $gray-400 !important;
  }
  &::before {
    content: "";
    width: inherit;
    height: inherit;
    display: block;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba($color: #000000, $alpha: 0.1);
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    &::before {
      opacity: 1;
    }
  }
}
