@import "_variables.scss";

.item-text {
  font-family: $font-family-secondary;
  color: $text-color;
  font-size: 13px;
  line-height: 19px;
  font-weight: 500;
  margin: 0;

  &.alt {
    .label {
      font-size: 12px;
      font-weight: 500;
      color: $warm-neutrals-v2-50;
    }
    .value {
      font-size: 12px;
      font-weight: 500;
      color: $warm-neutrals-v2-50;
    }
  }

  .label {
    font-size: 13px;
    color: $warm-neutrals-v2-80;
    font-weight: 500;
  }
  .value {
    font-size: 13px;
    &::before {
      content: "-";
      margin: 0 10px;
    }
    font-weight: 400;
  }
}
