@import "_variables.scss";

.sc-items--item {
  &.alternative {
    border-radius: 10px;
    border: 1px solid $warm-neutrals-10;
    background: $white;
    box-sizing: border-box;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
    .sc-grey--block {
      background-color: transparent;
      padding: 0;
      border-radius: 0;
      .sc-grey--block-left {
        font-size: 14px;
        font-weight: 600;
      }
    }
    .sc-items--item-content {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 15px;

      & > div {
        margin: 0;
      }
    }
  }
}

.sc-items--item-content {
  padding: 1.25rem 0.875rem 1.875rem 0.875rem;
}

.sc-grey--block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $gray-500;
  padding: 0.5rem 0.875rem;
  border-radius: 5px;
}

.sc-grey--block-left {
  margin-bottom: 0;
  font-family: $font-family-secondary;
  font-weight: $font-weight-semibold;
  font-size: $font-size-base * 0.875;
  line-height: $line-height-base * 1.1;
  color: $text-color;
}
