.form-wrap {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: "stretch";
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

