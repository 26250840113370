@import "../../../styles/_variables.scss";

.transport {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  padding-bottom: 10px;

  .item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: stretch;
    .heading {
      color: $warm-neutrals-80;
      font-family: $font-family-secondary;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      margin: 0;
    }
    .body {
      color: $warm-neutrals-80;
      font-family: $font-family-secondary;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      margin: 0;
    }
  }
}

