@import "_variables.scss";

.sc-prefrences--more {
  button {
    display: flex;
    align-items: center;
    font-family: $font-family-secondary;
    font-weight: $font-weight-semibold;
    font-size: $font-size-base * 0.875;
    line-height: $line-height-base * 0.84;
    color: #9e9e9e;
    span {
      text-decoration: underline;
    }
    i {
      margin-right: 9px;
      font-size: 20px;
    }
  }
}
