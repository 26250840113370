@import "_variables.scss";
@import "_mixins.scss";

.sc-message--form {
  &.minimal {
    .input-wrap {
      background-color: #fff;
      border-radius: 100px;
      overflow: hidden;
      padding: 0 15px;
      border: 1px solid $warm-neutrals-20;
    }
  }
}

.sc-message--type {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  > span {
    font-weight: $font-weight-light;
    font-size: $font-size-base 0.875;
    line-height: $line-height-base * 0.84;
    color: $text-color;
    margin-right: 0.75rem;
  }
  > div {
    margin-right: 0.625rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
