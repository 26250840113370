@import "_variables.scss";

.kanban_wrap {
  font-size: 1rem;
  display: flex;
  height: 100%;
  margin-top: 1px;
  overflow: hidden;
  overflow-x: auto;
  width: 100%;
  scrollbar-color: $warm-neutrals-30 transparent;
  scrollbar-width: thin;
  .inner {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  &.paper {
    border: 1px solid $warm-neutrals-10;
    padding: 15px 10px;
    box-sizing: border-box;
    background-color: $warm-neutrals-10;
    border-radius: 10px;
  }
}
