@import "_variables.scss";

.document_location_item {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
  padding-bottom: 10px;
  border-bottom: 1px solid $warm-neutrals-v2-30;
}
