@import "_variables.scss";

.opportunity_status_icon {
  width: 22px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  gap: 7px;
  background-color: $warm-neutrals-20;
  box-sizing: border-box;
  color: #fff;
  position: relative;
  cursor: pointer;

  &.accepted,
  &.transaction-created {
    background-color: $green;
  }
  &.rejected {
    background-color: $error-20;
  }
  &.pending {
    background-color: #ecbe30;
  }

  &.accepted-pending {
    background-color: #ecbe30;
  }

  &:hover {
    &::before {
      content: "";
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 11px 5.5px 0 5.5px;
      border-color: $warm-neutrals-60 transparent transparent transparent;
      transform: rotate(0deg);
      position: absolute;
      left: 5px;
      top: -4px;
      z-index: 10;
    }
    &::after {
      content: attr(data-tooltip);
      color: #fff;
      text-align: center;
      font-family: $font-family-secondary;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      background-color: #626262;
      padding: 5px 10px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -200%;
      left: -25%;
      z-index: 10;
      width: 175px;
    }
  }
}
